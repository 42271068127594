@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ul,
    ol {
        list-style: revert;
    }
}

@layer utilities {
    .lumina-box-shadow {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    }
}

.logo-login {
    height: 34px;
}

.logo-auth {
    height: 24px;
}

.fake-disabled {
    opacity: 0.32 !important;
}

@media only screen and (min-width: 768px) {
    .logo-login {
        height: 50px;
    }
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input {
    position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
}

._xlcmb {
    display: none !important;
}

._QLjfS {
    overflow-y: hidden !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
